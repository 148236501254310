import { Component, createMemo, JSX, Show } from 'solid-js';
import { cn } from '~/utils/classnames';

export interface LabeledGroupProps {
  class?: string;
  label?: string;
  labelJSX?: JSX.Element;
  type?: string;
  id?: string;
  isInvalid?: boolean;
  children?: JSX.Element;
  error?: string | undefined;
  labelClass?: string;
  required?: boolean;
}

const LabeledGroup: Component<LabeledGroupProps> = (props) => {
  const id = createMemo(() => props.id || props.label?.replace(/[^a-zA-Z0-9]/g, ''));
  const isInvalid = createMemo(() => !!props.error);

  const renderLabelContent = () => {
    if (props.labelJSX) {
      return props.labelJSX;
    }

    if (props.label) {
      return props.label;
    }
    return null;
  };

  return (
    <div class={cn('relative', props.class)}>
      <div class="flex flex-col gap-2">
        <Show when={props.label || props.labelJSX}>
          <label class={cn('text-sm uppercase text-label', props.labelClass)} for={id()}>
            {renderLabelContent()}
            <Show when={props.required}>
              <span class="text-error"> *</span>
            </Show>
          </label>
        </Show>
        {props.children}
      </div>
      <Show when={isInvalid()}>
        <span class="absolute mt-2 text-xs text-red-500">{props.error}</span>
      </Show>
    </div>
  );
};

export default LabeledGroup;
